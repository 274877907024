<template>
	<!--  v-if="canViewVerticalNavMenuLink(item)"-->
	<li
		:class="{
			active: isActive,
			disabled: item.disabled,
		}"
		class="nav-item"
	>
		<b-link class="d-flex align-items-center" v-bind="linkProps" @click="commitInnerCurrentMenu(item)">
			<feather-icon :icon="item.icon || 'CircleIcon'" />
			<span class="menu-title text-truncate">{{ t(item.title) }}</span>
			<b-badge v-if="item.tag" :variant="item.tagVariant || 'primary'" class="mr-1 ml-auto" pill>
				{{ item.tag }}
			</b-badge>
		</b-link>
	</li>
</template>

<script>
	import { useUtils as useAclUtils } from "@core/libs/acl";
	import { BBadge, BLink } from "bootstrap-vue";
	import { useUtils as useI18nUtils } from "@core/libs/i18n";
	import useVerticalNavMenuLink from "./useVerticalNavMenuLink";
	import mixinVerticalNavMenuLink from "./mixinVerticalNavMenuLink";
	import store from "@/store";

	export default {
		components: {
			BLink,
			BBadge,
		},
		mixins: [mixinVerticalNavMenuLink],
		props: {
			item: {
				type: Object,
				required: true,
			},
		},
		setup(props) {
			const { isActive, linkProps, updateIsActive } = useVerticalNavMenuLink(props.item);
			const { t } = useI18nUtils();
			const { canViewVerticalNavMenuLink } = useAclUtils();
			const commitInnerCurrentMenu = (item) => {
				console.log("commitInnerCurrentMenu", item);
				store.commit("menus/UPDATE_MENU_INNER_MENU_CURRENT", item);
			};
			return {
				isActive,
				linkProps,
				updateIsActive,
				// ACL
				canViewVerticalNavMenuLink,
				commitInnerCurrentMenu,
				// i18n
				t,
			};
		},
	};
</script>
